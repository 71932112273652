export default {
  data() {
    return {
      rules: {
        range(from, to) {
          return (v) => (v >= from && v <= to) || (`The number should be between ${from} and ${to}`);
        },
        minLength(min) {
          return (v) => (v.length >= min) || (`The value should contain at least ${min} characters.`);
        },
        maxLength(max) {
          return (v) => (v.length <= max) || (`The value should contain at maximum ${max} characters.`);
        },
        noEmptyArray() {
          return (v) => (Array.isArray(v) && v.length !== 0) || ('One of these Options should be selected.');
        },
      },
    };
  },
};
