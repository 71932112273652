export default {
  data() {
    return {
      formErrors: {},
    };
  },
  props: {},
  computed: {},
  methods: {
    mapSymfonyErrors(errors) {
      const formErrors = {};
      const keys = Object.keys(errors.children);
      keys.forEach((key) => {
        if (Array.isArray(errors.children[key].errors)) {
          const [langKey, errorMsg] = errors.children[key].errors;
          if (errorMsg !== undefined && errorMsg.length > 0) {
            formErrors[key] = [errorMsg];
          } else if (langKey !== undefined) {
            formErrors[key] = [langKey];
          }
        }
      });
      this.formErrors = formErrors;
    },
  },
};
