<template>
  <div v-if="errors && errors.length >0" class="has-error error--text">
    <slot></slot>
    <p v-for="(error,index) in errors" class="error-message " :key="index">
      {{ error }}
    </p>
  </div>
  <div v-else>
    <slot></slot>
  </div>

</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
.has-error .v-label {
  color: #ff1744;
}
.has-error .error-message {
  /*text-align: center;*/
  color: #ff1744;
  font-size: 12px;
  margin: 0;
  margin-bottom: 20px;
}
</style>
